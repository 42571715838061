import { Injectable } from '@angular/core'; // Or other dependency injection library
import { HttpClient } from '@angular/common/http'; // For making HTTP requests
import { Observable } from 'rxjs';

// Import your DTO interfaces/classes
import {environment} from "../../environments/environment";
import {ChartOfAccounts} from "./chart-of-accounts.model";
import {COASegmentName} from "./coa-segment-name.model";
import {COASegmentValue} from "./coa-segment-value.model";
import {COASegmentMapping} from "./coa-segment-mapping.model";


@Injectable({
    providedIn: 'root' // Or provide in the relevant module
})
export class ChartOfAccountsService {

    private baseUrl = `${environment.restApiUrl}chart-of-accounts`; // Base URL for your API

    constructor(private http: HttpClient) { }

    // Chart of Accounts Methods
    getChartOfAccountsById(id: number): Observable<ChartOfAccounts> {
        return this.http.get<ChartOfAccounts>(`${this.baseUrl}/${id}`);
    }

    createChartOfAccounts(chartOfAccounts: ChartOfAccounts): Observable<ChartOfAccounts> {
        return this.http.post<ChartOfAccounts>(this.baseUrl, chartOfAccounts);
    }

    updateChartOfAccounts(chartOfAccounts: ChartOfAccounts): Observable<ChartOfAccounts> {
        return this.http.put<ChartOfAccounts>(this.baseUrl, chartOfAccounts);
    }

    deleteChartOfAccounts(id: number): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/${id}`);
    }


    getAllChartOfAccountsByOwningCompany(companyId: number): Observable<ChartOfAccounts[]> {
        return this.http.get<ChartOfAccounts[]>(`${this.baseUrl}/company/${companyId}`);
    }


    // COA Segment Name Methods
    getCOASegmentNameByIdAndOwningCompany(id: number, companyId: number): Observable<COASegmentName> {
        return this.http.get<COASegmentName>(`${this.baseUrl}/segments/${id}/${companyId}`);
    }

    createCOASegmentName(coaSegmentName: COASegmentName): Observable<COASegmentName> {
        return this.http.post<COASegmentName>(`${this.baseUrl}/segments`, coaSegmentName);
    }

    updateCOASegmentName(coaSegmentName: COASegmentName): Observable<COASegmentName> {
        return this.http.put<COASegmentName>(`${this.baseUrl}/segments`, coaSegmentName);
    }

    deleteCOASegmentName(id: number): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/segments/${id}`);
    }

    getAllCOASegmentNamesByOwningCompany(companyId: number): Observable<COASegmentName[]> {
        return this.http.get<COASegmentName[]>(`${this.baseUrl}/segments/company/${companyId}`);
    }


    // COA Segment Value Methods
    getCOASegmentValueByIdAndOwningCompany(id: number, companyId: number): Observable<COASegmentValue> {
        return this.http.get<COASegmentValue>(`${this.baseUrl}/values/${id}/${companyId}`);
    }

    createCOASegmentValue(coaSegmentValue: COASegmentValue): Observable<COASegmentValue> {
        return this.http.post<COASegmentValue>(`${this.baseUrl}/values`, coaSegmentValue);
    }

    updateCOASegmentValue(coaSegmentValue: COASegmentValue): Observable<COASegmentValue> {
        return this.http.put<COASegmentValue>(`${this.baseUrl}/values`, coaSegmentValue);
    }

    deleteCOASegmentValue(id: number): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/values/${id}`);
    }

    getAllCOASegmentValuesByOwningCompany(companyId: number): Observable<COASegmentValue[]> {
        return this.http.get<COASegmentValue[]>(`${this.baseUrl}/values/company/${companyId}`);
    }


    // COA Segment Mapping Methods
    getAllCOASegmentMappingsByChartOfAccountId(chartOfAccountId: number): Observable<COASegmentMapping[]> {
        return this.http.get<COASegmentMapping[]>(`${this.baseUrl}/mappings/${chartOfAccountId}`);
    }

    createCOASegmentMapping(coaSegmentMapping: COASegmentMapping): Observable<COASegmentMapping> {
        return this.http.post<COASegmentMapping>(`${this.baseUrl}/mappings`, coaSegmentMapping);
    }

    updateCOASegmentMapping(coaSegmentMapping: COASegmentMapping): Observable<COASegmentMapping> {
        return this.http.put<COASegmentMapping>(`${this.baseUrl}/mappings`, coaSegmentMapping);
    }

    deleteCOASegmentMapping(id: number): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/mappings/${id}`);
    }


}
