import { Injectable } from '@angular/core';
import { Company } from '../company/company.model';
import { Customer } from '../customer/customer.model';
import { OrderDeliveryView } from '../models/order-view/order-delivery';
import { OrderQueryView } from '../models/order-view/order-query-view';
import { BaseMasterData } from 'src/app/models/common/base-master-data.model';
import { VehicleType } from 'src/app/fleet/vehicle/params/vehicle-type';
import { VehicleMake } from 'src/app/fleet/vehicle/params/vehicle-make';
import { UOM } from 'src/app/models/order-params/uom';
import { Country } from 'src/app/models/common/country';
import { Weight } from 'src/app/models/order-params/weight';
import { Length } from 'src/app/models/order-params/length';
import { PayloadType } from 'src/app/models/order-params/payload-type';
import { TripType } from 'src/app/models/vehicle/trip-type';
import { Location } from 'src/app/location/location.model';
import { Contracts } from "../contracts/models/contracts.model";
import { TransportationAgency } from "../company/transportation-agency/transportation-agency.model";
import {FreightForwarder} from "../company/freight-forwarder/freight-forwarder.model";


const ACCESS_TOKEN_KEY = 'auth-access-token';
const ACCESS_TOKEN_EXPIRY = 'auth-access-expiry';
const REFRESH_TOKEN_KEY = 'auth-refresh-token';
const USER_KEY = 'auth-user';
const CUSTOMER_KEY = 'auth-customer';
const COMPANY_KEY = 'auth-company';
const ORDER_DETAIL_KEY = 'order-query';
const DELIVERY_DETAIL_KEY = 'order-delivery';
const VEHICLE_TYPES_KEY = 'vehicle-types';
const VEHICLE_MAKES_KEY = 'vehicle-makes';
const UOMS_KEY = 'uoms';
const COUNTRY_KEY = 'countries';
const WEIGHT_KEY = 'weight-units';
const LENGTH_KEY = 'length-units';
const PAYLOAD_TYPE_KEY = 'payload-types';
const TRIP_TYPE_KEY = 'trip-types';
const LOCATION_LIST_KEY = 'location-list';
const CURRENT_CONTRACT_KEY = 'current-contract';


@Injectable({
    providedIn: 'root'
})
export class SessionStorageService {

    constructor() { }

    signOut(): void {
        window.sessionStorage.clear();
    }

    public saveAccessToken(token: string, expiry: number): void {
        window.sessionStorage.removeItem(ACCESS_TOKEN_KEY);
        window.sessionStorage.setItem(ACCESS_TOKEN_KEY, token);
        window.sessionStorage.removeItem(ACCESS_TOKEN_EXPIRY);
        window.sessionStorage.setItem(ACCESS_TOKEN_EXPIRY, expiry.toString());
    }

    public getAccessToken(): string | null {
        return window.sessionStorage.getItem(ACCESS_TOKEN_KEY);
    }

    public getAccessExpiry(): number | null {
        let tokenCreationDate = Number.parseInt(window.sessionStorage.getItem(ACCESS_TOKEN_EXPIRY)!!);
        tokenCreationDate += 3600000;
        return tokenCreationDate;
    }

    public saveRefreshToken(token: string): void {
        window.sessionStorage.removeItem(REFRESH_TOKEN_KEY);
        window.sessionStorage.setItem(REFRESH_TOKEN_KEY, token);
    }

    public getRefreshToken(): string | null {
        return window.sessionStorage.getItem(REFRESH_TOKEN_KEY);
    }

    public saveUser(user: any): void {
        window.sessionStorage.removeItem(USER_KEY);
        window.sessionStorage.setItem(USER_KEY, JSON.stringify(user));
    }

    public saveCustomer(customer: Customer): void {
        window.sessionStorage.removeItem(CUSTOMER_KEY);
        window.sessionStorage.setItem(CUSTOMER_KEY, JSON.stringify(customer));
        if (customer.company !== null) {
            window.sessionStorage.removeItem(COMPANY_KEY);
            window.sessionStorage.setItem(COMPANY_KEY, JSON.stringify(customer.company));
        }
    }

    public getUser(): any {
        const user = window.sessionStorage.getItem(USER_KEY);
        if (user) {
            return JSON.parse(user);
        }
        return null;
    }

    public getCustomer(): Customer | undefined {
        const customer = window.sessionStorage.getItem(CUSTOMER_KEY);
        if (customer) {
            return JSON.parse(customer);
        }
        return undefined;
    }

    public getCompany(): Company | undefined{
        const company = window.sessionStorage.getItem(COMPANY_KEY);
        if (company) {
            return JSON.parse(company);
        }
        return undefined;
    }

    public setCurrentOrder(orderQuery: OrderQueryView) {
        window.sessionStorage.removeItem(ORDER_DETAIL_KEY);
        window.sessionStorage.setItem(ORDER_DETAIL_KEY, JSON.stringify(orderQuery));
    }

    public getCurrentOrder(): OrderQueryView | undefined{
        const orderQuery = window.sessionStorage.getItem(ORDER_DETAIL_KEY);
        if (orderQuery) {
            return JSON.parse(orderQuery);
        }
        return undefined;
    }

    public setCurrentDelivery(orderDelivery: OrderDeliveryView) {
        window.sessionStorage.removeItem(DELIVERY_DETAIL_KEY);
        window.sessionStorage.setItem(DELIVERY_DETAIL_KEY, JSON.stringify(orderDelivery));
    }

    public getCurrentDelivery(): OrderDeliveryView | undefined{
        const orderDelivery = window.sessionStorage.getItem(DELIVERY_DETAIL_KEY);
        if (orderDelivery) {
            return JSON.parse(orderDelivery);
        }
        return undefined;
    }

    public saveBaseMasterData(baseMasterData: BaseMasterData) {
        window.sessionStorage.removeItem(VEHICLE_TYPES_KEY);
        window.sessionStorage.setItem(VEHICLE_TYPES_KEY, JSON.stringify(baseMasterData.vehicleTypes));
        window.sessionStorage.removeItem(VEHICLE_MAKES_KEY);
        window.sessionStorage.setItem(VEHICLE_MAKES_KEY, JSON.stringify(baseMasterData.vehicleMakes));
        window.sessionStorage.removeItem(UOMS_KEY);
        window.sessionStorage.setItem(UOMS_KEY, JSON.stringify(baseMasterData.uomList));
        window.sessionStorage.removeItem(COUNTRY_KEY);
        window.sessionStorage.setItem(COUNTRY_KEY, JSON.stringify(baseMasterData.countryList));
        window.sessionStorage.removeItem(WEIGHT_KEY);
        window.sessionStorage.setItem(WEIGHT_KEY, JSON.stringify(baseMasterData.weightUnits));
        window.sessionStorage.removeItem(LENGTH_KEY);
        window.sessionStorage.setItem(LENGTH_KEY, JSON.stringify(baseMasterData.lengthUnits));
        window.sessionStorage.removeItem(PAYLOAD_TYPE_KEY);
        window.sessionStorage.setItem(PAYLOAD_TYPE_KEY, JSON.stringify(baseMasterData.payloadTypes));
        window.sessionStorage.removeItem(TRIP_TYPE_KEY);
        window.sessionStorage.setItem(TRIP_TYPE_KEY, JSON.stringify(baseMasterData.tripTypes));
    }

    public getVehicleTypes(): VehicleType[] | undefined{
        const vehicleTypes = window.sessionStorage.getItem(VEHICLE_TYPES_KEY);
        if (vehicleTypes) {
            return JSON.parse(vehicleTypes);
        }
        return undefined;
    }

    public getVehicleMakes(): VehicleMake[] | undefined{
        const vehicleMakes = window.sessionStorage.getItem(VEHICLE_MAKES_KEY);
        if (vehicleMakes) {
            return JSON.parse(vehicleMakes);
        }
        return undefined;
    }

    public getUOMList(): UOM[] | undefined{
        const uomList = window.sessionStorage.getItem(UOMS_KEY);
        if (uomList) {
            return JSON.parse(uomList);
        }
        return undefined;
    }

    public getCountryList(): Country[] | undefined{
        const countryList = window.sessionStorage.getItem(COUNTRY_KEY);
        if (countryList) {
            return JSON.parse(countryList);
        }
        return undefined;
    }

    public getWeightUnits(): Weight[] | undefined{
        const weightUnitList = window.sessionStorage.getItem(WEIGHT_KEY);
        if (weightUnitList) {
            return JSON.parse(weightUnitList);
        }
        return undefined;
    }

    public getLengthUnits(): Length[] | undefined{
        const lengthUnits = window.sessionStorage.getItem(LENGTH_KEY);
        if (lengthUnits) {
            return JSON.parse(lengthUnits);
        }
        return undefined;
    }

    public getPayloadTypes(): PayloadType[] | undefined{
        const payloadTypeList = window.sessionStorage.getItem(PAYLOAD_TYPE_KEY);
        if (payloadTypeList) {
            return JSON.parse(payloadTypeList);
        }
        return undefined;
    }

    public getTripTypes(): TripType[] | undefined{
        const tripTypeList = window.sessionStorage.getItem(TRIP_TYPE_KEY);
        if (tripTypeList) {
            return JSON.parse(tripTypeList);
        }
        return undefined;
    }

    setCurrentContract(contract: Contracts): void {
        window.sessionStorage.removeItem(CURRENT_CONTRACT_KEY);
        window.sessionStorage.setItem(CURRENT_CONTRACT_KEY, JSON.stringify(contract));
    }

    getCurrentContract(): Contracts | undefined {
        const contract = window.sessionStorage.getItem(CURRENT_CONTRACT_KEY);
        if (contract) {
            return JSON.parse(contract);
        }
        return undefined;
    }

    saveTransportationAgency(agency: TransportationAgency): void {
        sessionStorage.setItem('transportationAgency', JSON.stringify(agency));
    }

    getTransportationAgency(): TransportationAgency | null {
        const agencyData = sessionStorage.getItem('transportationAgency');
        return agencyData ? JSON.parse(agencyData) : null;
    }

    saveFreightForwarder(freightForwarder: FreightForwarder): void {
        sessionStorage.setItem('freightForwarder', JSON.stringify(freightForwarder));
    }

    getFreightForwarder(): FreightForwarder | null {
        const freightForwarderData = sessionStorage.getItem('freightForwarder');
        return freightForwarderData ? JSON.parse(freightForwarderData) : null;
    }


}
