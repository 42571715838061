import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule, RouterOutlet } from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DataViewModule } from 'primeng/dataview';
import { CardModule } from 'primeng/card';
import { ButtonModule } from "primeng/button";
import { ToastModule } from "primeng/toast";
import { ProgressBarModule } from 'primeng/progressbar';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TableModule } from 'primeng/table';
import { InputTextModule } from "primeng/inputtext";
import { DialogModule } from "primeng/dialog";
import { ChipModule } from 'primeng/chip';
import { ImageModule } from 'primeng/image';
import {MessageService} from "primeng/api";
import {TabViewModule} from "primeng/tabview";
import {AutoCompleteModule} from "primeng/autocomplete";

import {chartOfAccountRoutes} from "./chart-of-accounts.routes";
import {ChartOfAccountsComponent} from "./chart-of-accounts.component";
import {ChartOfAccountsService} from "./chart-of-accounts.service";





@NgModule({
    imports: [
        RouterModule.forChild(chartOfAccountRoutes),
        RouterOutlet,
        FontAwesomeModule,
        FormsModule,
        ButtonModule,
        DataViewModule,
        CardModule,
        CommonModule,
        ToastModule,
        ButtonModule,
        ProgressBarModule,
        BreadcrumbModule,
        TableModule,
        InputTextModule,
        DialogModule,
        ChipModule,
        ImageModule,
        TabViewModule,
        AutoCompleteModule,
        ReactiveFormsModule,

    ],
    providers: [
        MessageService,
        ChartOfAccountsService
    ],
    declarations: [
        ChartOfAccountsComponent
    ],
})
export class ChartOfAccountsModule { }
