import { Component, OnInit } from '@angular/core';
import { AuthService } from './services/auth.service';
import {MenuItem, MenuItemCommandEvent} from 'primeng/api';
import { SessionStorageService } from './services/session-storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToolbarService } from './services/toolbar.service';
import { Customer } from './customer/customer.model';
import { Company } from './company/company.model';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {EnterpriseFeatureService} from "./services/enterprise-features.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

    private roles: string[] = [];
    isLoggedIn: boolean = false;
    customer: Customer | null = null;
    company: Company | null = null;
    returnUrl = '';
    //showAdminBoard = false;
    //showModeratorBoard = false;
    username?: string;
    title = 'cargomate-portal';
    toolbarMenuItems: MenuItem[] = [];
    profileMenuItems: MenuItem[] = [];

    email: string = '';
    password: string = '';

    constructor(private authService: AuthService,
                private sessionStorageService: SessionStorageService,
                private router: Router, private route: ActivatedRoute,
                protected breakpointObserver: BreakpointObserver,
                private toolbarService: ToolbarService,
                private enterpriseFeatureService: EnterpriseFeatureService) { }

    ngOnInit() {
        this.toolbarService.isLoggedInObservable.subscribe(isLoggedIn => {
            const user = this.sessionStorageService.getUser();
            this.isLoggedIn = user != null;
            console.log('This is the updated logged in status: ', isLoggedIn);
            if (this.isLoggedIn) {
                this.roles = user.roles;
                this.authService.getCurrentUserAccessToken().subscribe(response => {
                    this.sessionStorageService.saveAccessToken(response, new Date().getTime());
                })
                this.username = user.username;
            } else {
                this.router.navigate(['/login'], { queryParams: { returnUrl: window.location.href } });
            }
        });

        this.toolbarService.customerObservable.subscribe(customer => {
            if (customer !== null) {
                this.customer = customer;
                this.company = customer?.company!!;
            } else if (this.sessionStorageService.getCustomer()) {
                this.customer = this.sessionStorageService.getCustomer()!;
                this.company = this.customer?.company;
            };
            console.log('This is the updated customer: ', JSON.stringify(this.customer));
        })

        this.toolbarMenuItems = [{
            label: 'AI Chat', icon: 'fa-solid fa-robot',
            routerLink: ['/ai-chat'],
        },
            {
                label: 'Shipments', icon: 'fa-solid fa-truck-ramp-box',
                items: [
                    { label: 'Create Shipment', icon: 'fa-solid fa-folder-plus', routerLink: ['/shipment/create'] },
                    { label: 'My Current Shipments', icon: 'fa-regular fa-square-caret-right', routerLink: ['/shipment'] },
                    { label: 'Current Company Shipments', icon: 'fa-solid fa-truck-fast', routerLink: ['/shipment/company'] }
                ]
            },
            {
                label: 'Accounts', icon: 'fa-solid fa-cash-register',
                items: [
                    { label: 'Invoices', icon: 'fa-solid fa-file-invoice', routerLink: ['/accounts/invoice'] },
                    { label: 'Receipts', icon: 'fa-solid fa-receipt', routerLink: ['/accounts/receipt'] },
                    { label: 'Enterprise', icon: 'fa-solid fa-windows', visible: this.enterpriseFeatureService.getEnableEnterpriseAccounts(),
                        items: [
                            { label: 'Receivables', icon: 'fa-solid fa-circle-down', routerLink: ['/accounts/enterprise/receivables'],
                                items: [
                                    { label: 'Generated Invoices', icon: 'fa-solid fa-file-invoice', routerLink: ['/accounts/enterprise/receivables/generated-invoices'] },
                                    { label: 'Generated Receipts', icon: 'fa-solid fa-file-receipt', routerLink: ['/accounts/enterprise/receivables/generated-receipts'] }
                                ]
                            },
                        ]
                    }
                ]
            },
            {
                label: 'Fleet', icon: 'fa-solid fa-truck-fast', routerLink: ['/fleet'],
                items: [
                    { label: 'Drivers', icon: 'fa-regular fa-id-card', routerLink: ['/fleet/drivers'] },
                    { label: 'Vehicles', icon: 'fa-solid fa-truck-moving', routerLink: ['/fleet/vehicles'] }
                ]
            },
            {
                label: 'Contracts', icon: 'fa-solid fa-file-contract', routerLink: ['/contracts']
            },
            {
                label: 'Setup',
                icon: 'fa-solid fa-gear',
                items: [
                    { label: 'Location', icon: 'fa-sold fa-location-pin', routerLink: ['/location'] },
                    { label: 'Chart Of Accounts', icon: 'fa-sold fa-file-invoice', routerLink: ['/chart-of-accounts'] }
                ]
            }];
        this.profileMenuItems = [
            {
                label: 'Profile', icon: 'fa-regular fa-id-card', routerLink: ['/customer']
            },
            {
                label: 'Sign Out', icon: 'fa-solid fa-right-from-bracket', command: () => this.logout()
            }
        ];
    }

    signUp() {
        this.authService.register(this.email, this.password);
        this.email = '';
        this.password = '';
    }

    signIn() {
        this.authService.login(this.email, this.password);
        this.email = '';
        this.password = '';
    }

    logout(): void {
        this.sessionStorageService.signOut();
        this.toolbarService.updateLoggedInStatus(false);
        window.location.reload();
        this.authService.logout();
    }

    protected readonly Breakpoints = Breakpoints;
}
