import { Component } from '@angular/core';
import {OrderQueryView} from "../../../models/order-view/order-query-view";
import {OrderDeliveryView} from "../../../models/order-view/order-delivery";
import {JobAttachment} from "../../../models/order-view/job-attachment";
import {OrderJobStatus} from "../../../models/order-view/order-job-status";
import {MenuItem, PrimeIcons} from "primeng/api";
import {ActivatedRoute} from "@angular/router";
import {AngularFireDatabase, SnapshotAction} from "@angular/fire/compat/database";
import {DialogService} from "primeng/dynamicdialog";
import {MapDirectionsService} from "@angular/google-maps";
import {SessionStorageService} from "../../../services/session-storage.service";
import {VehicleKeyPack} from "../../../services/vehicle-key-pack.service";
import {GeoFireModel} from "../../../models/vehicle/geo-fire.model";
import {formatDate} from "@angular/common";
import {JobStatus} from "../../../models/order-view/job-status";
import {synchronized} from "synchronized-ts";
import {PaymentStatus} from "../../../models/order-view/payment-status";
import {DownloadUrlCallback} from "../../../services/google/google-cloud-storage.service";
import {FileUploadComponent} from "../../../helpers/file-upload/file-upload.component";

@Component({
  selector: 'app-telematics',
  templateUrl: './telematics.component.html',
  styleUrls: ['./telematics.component.css']
})
export class TelematicsComponent {

    deliveryNumber: string;
    orderQuery: OrderQueryView;
    orderDelivery: OrderDeliveryView;
    deliveryFilesList: JobAttachment[] = [];
    deliveryFilesCount: number = 0;
    isLoading: boolean = true;
    orderJobStatus: OrderJobStatus = new OrderJobStatus();
    breadcrumbItems: MenuItem[];
    homeBreadcrumb: MenuItem;

    // Timeline data
    timelineEvents: any[] = [];

    googleMap: google.maps.Map;

    /*@ViewChild(google.maps.InfoWindow, { static: false })
    infoWindow: google.maps.InfoWindow
    infoContent = 'testing all'*/

    showRoute = false;
    zoom = 12
    vehicleMarkerImage = "https://storage.googleapis.com/prologix.appspot.com/basic_data/admin_files/TractorUnitBlack.png"
    center: google.maps.LatLngLiteral;
    options: google.maps.MapOptions = {
        mapTypeId: 'roadmap',
        zoomControl: true,
        scrollwheel: true,
        disableDoubleClickZoom: true,
        maxZoom: 15,
        minZoom: 8
    }
    vehicleLocation: google.maps.LatLngLiteral;
    pickupLocation: google.maps.LatLngLiteral;
    dropoffLocation: google.maps.LatLngLiteral;
    directionsResults: google.maps.DirectionsResult;

    constructor(private route: ActivatedRoute,
                private database: AngularFireDatabase,
                private dialogService: DialogService,
                private mapDirectionsService: MapDirectionsService,
                private sessionStorageService: SessionStorageService) {
        this.vehicleLocation = { lat: 21.485811, lng: 39.192505 };
    }

    ngOnInit(): void {
        this.route.params.subscribe(params => {
            console.log(params); // { order: "popular" }
            this.deliveryNumber = params['deliveryNumber'];
            console.log(this.deliveryNumber);
            this.orderDelivery = this.sessionStorageService.getCurrentDelivery()!!;
            console.log(this.orderDelivery);
            this.orderQuery = this.sessionStorageService.getCurrentOrder()!!;
            this.deliveryFilesList = this.orderDelivery?.filesList!!;
            this.deliveryFilesCount = this.orderDelivery?.filesList?.length!!;
            this.isLoading = false;

            this.breadcrumbItems = [{ label: 'Orders', routerLink: '/shipment' },
                { label: this.orderQuery?.queryId, routerLink: ['/shipment/view', this.orderQuery?.queryId] },
                { label: this.orderDelivery?.deliveryText }];
            this.homeBreadcrumb = { icon: 'pi pi-home', routerLink: '/dashboard' };

            let vehicleKeyPack = new VehicleKeyPack().fromData(this.orderDelivery?.deliveryVehicle!!);
            let keyPackString = vehicleKeyPack.toString();
            console.log('keypack string: ' + keyPackString);
            const locationsRef = this.database.object('vehicle/location/all/' + keyPackString);
            // Attach an asynchronous callback to read the data at our posts reference
            locationsRef.snapshotChanges().subscribe(snapShot => {
                console.log(snapShot.type);
                console.log(snapShot.key);
                console.log(snapShot.payload.val());
                let geoFireModel = <GeoFireModel>snapShot.payload.val();
                if (geoFireModel != null) {
                    this.vehicleLocation = { lat: geoFireModel.l[0], lng: geoFireModel.l[1] };
                }
            });

            // Fetch pickup and dropoff locations from your data (e.g., from your orderDelivery object)
            this.pickupLocation = { lat: this.orderQuery.sourceLocation.location.latitude, lng: this.orderQuery?.sourceLocation.location.longitude };
            this.dropoffLocation = { lat: this.orderQuery.destinationLocation.location.latitude, lng: this.orderQuery.destinationLocation.location.longitude };
            //this.vehicleLocation = { lat: this.orderQuery.sourceLocation.location.latitude, lng: this.orderQuery?.sourceLocation.location.longitude };

            const request: google.maps.DirectionsRequest = {
                destination: this.dropoffLocation,
                origin: this.pickupLocation,
                travelMode: google.maps.TravelMode.DRIVING,
                unitSystem: google.maps.UnitSystem.METRIC
            };
            this.mapDirectionsService.route(request).subscribe(directionsResponse => {
                this.directionsResults = directionsResponse.result!;
                this.showRoute = true;
            });

            this.populateTimelineEvents();
            this.getLiveJobWithStatus(this.orderQuery, this.orderDelivery);
        });
        navigator.geolocation.getCurrentPosition((position) => {
            this.center = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
            }
        })
    }

    showDate(longDate: number | undefined) {
        return longDate != undefined ? formatDate(new Date(longDate), 'dd-MM-yyyy HH:mm', 'en-US') : undefined;
    }

    openInfo(_t13: HTMLElement) {
        throw new Error('Method not implemented.');
    }

    populateTimelineEvents() {
        // Example timeline events based on orderDelivery status
        this.timelineEvents = [
            {
                icon: PrimeIcons.CHECK_CIRCLE,
                content: 'Order Created',
                date: this.showDate(this.orderJobStatus?.jobStatusMap[JobStatus.SELECTING_VEHICLE]),
                styleClass: 'order-created'
            },
            {
                icon: 'pi pi-truck',
                content: 'Arrived at Pickup',
                date: this.showDate(this.orderJobStatus?.jobStatusMap[JobStatus.DRIVER_REACHED_PICKUP]),
                styleClass: 'pickup-confirmed'
            },
            {
                icon: 'pi pi-map-marker',
                content: 'Loading completed',
                date: this.showDate(this.orderJobStatus?.jobStatusMap[JobStatus.DRIVER_ORDER_SIGNOFF]),
                styleClass: 'delivery-in-progress'
            },
            {
                icon: 'pi pi-check-circle',
                content: 'Delivery En Route',
                date: this.showDate(this.orderJobStatus?.jobStatusMap[JobStatus.DRIVER_EN_ROUTE]),
                styleClass: 'delivery-completed'
            },
            {
                icon: 'pi pi-check-circle',
                content: 'Reached Dropoff Location',
                date: this.showDate(this.orderJobStatus?.jobStatusMap[JobStatus.DRIVER_REACHED_DROPOFF]),
                styleClass: 'payment-received'
            },
            {
                icon: 'pi pi-money-bill',
                content: 'Signed Waybill Uploaded',
                date: this.showDate(this.orderJobStatus?.jobStatusMap[JobStatus.JOB_COMPLETION_ACKNOWLEDGE]),
                styleClass: 'payment-received'
            }
        ];
    }

    getLiveJobWithStatus(orderQuery: OrderQueryView, orderDelivery: OrderDeliveryView) {
        const jobStatusRef = this.database.list(`orderJob/${orderQuery.queryId}/${orderDelivery.deliveryText}`);
        jobStatusRef.stateChanges(['child_added']).subscribe(snapShot => {
            console.log("Job Status Added: " + JSON.stringify(snapShot));
            this.updateOrderJobStatus(snapShot);

        });
        jobStatusRef.stateChanges(['child_changed']).subscribe(snapShot => {
            console.log("Job Status Change: " + JSON.stringify(snapShot));
            this.updateOrderJobStatus(snapShot);
        });
    }

    @synchronized
    updateOrderJobStatus(snapShot: SnapshotAction<any>) {
        if (snapShot.key?.includes('jobStatusMap'))
            this.orderJobStatus.jobStatusMap = snapShot.payload.val() as Map<JobStatus, number>;
        else if (snapShot.key?.includes('jobStatus'))
            this.orderJobStatus.jobStatus = snapShot.payload.val() as JobStatus;
        else if (snapShot.key?.includes('paymentStatus'))
            this.orderJobStatus.paymentStatus = snapShot.payload.val() as PaymentStatus;
        this.populateTimelineEvents();
    }

    addNewFile() {
        this.sessionStorageService.setCurrentOrder(this.orderQuery!!);
        this.sessionStorageService.setCurrentDelivery(this.orderDelivery!!);

        // Define the callback function
        const fileUploadCallback: DownloadUrlCallback = (file: JobAttachment) => {
            // Update the orderFilesList with the new file
            this.deliveryFilesList.push(file);
            this.deliveryFilesCount = this.deliveryFilesList.length;
            fileUploadDialog.close();
        };

        // Open the dialog with the callback
        let fileUploadDialog = this.dialogService.open(FileUploadComponent, {
            header: 'Create/Upload Order File',
            width: '50vw',
            data: { callback: fileUploadCallback },
        });
    }

    tabChange($event: Event) {

    }

    addNewChargeLine() {

    }

}
