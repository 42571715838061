// generated-receipts.component.ts
import { Component, OnInit } from '@angular/core';
import { AccountsService } from '../../accounts.service';
import { GeneratedReceiptsSummary } from './generated-receipts-summary.model';
import {ConfirmationService, MessageService} from "primeng/api";
import {Router} from "@angular/router";
import {MenuItem} from "primeng/api";
import {SessionStorageService} from "../../../services/session-storage.service";


@Component({
    selector: 'app-generated-receipts',
    templateUrl: './generated-receipts.component.html',
    styleUrls: ['./generated-receipts.component.css']
})
export class GeneratedReceiptsComponent implements OnInit {

    generatedReceiptsSummaryList: GeneratedReceiptsSummary[] = [];
    homeBreadcrumb: MenuItem = {icon: 'pi pi-home', routerLink: '/'};
    breadcrumbItems: MenuItem[] = [];
    isLoading = false;
    first = 0;
    rows = 20;

    constructor(
        private accountsService: AccountsService,
        private messageService: MessageService,
        private sessionStorageService: SessionStorageService
    ) {}

    ngOnInit(): void {
        this.breadcrumbItems = [
            {label: 'Dashboard', routerLink: '/dashboard'},
            {label: 'Accounts', routerLink: '/accounts'},
            {label: 'Receivables'},
            {label: 'Generated Receipts'}
        ];
        this.loadGeneratedReceiptsSummary();
    }



    loadGeneratedReceiptsSummary() {
        this.isLoading = true;
        // Replace with the appropriate method from your service to fetch the data
        this.accountsService.getGeneratedReceiptsSummary(this.sessionStorageService.getCompany()!!.id).subscribe(
            data => {
                this.generatedReceiptsSummaryList = data;
                this.isLoading = false;
            },
            error => {
                this.isLoading = false;
                this.messageService.add({severity: 'error', summary: 'Error', detail: 'Failed to load generated receipts summary.'});
            }
        );
    }

    loadData(event: any, generatedReceiptsSummary: GeneratedReceiptsSummary) {
        this.first = event.first;
        this.rows = event.rows;
        this.isLoading = true;
        this.accountsService.getReceipts(this.first, this.rows,
            generatedReceiptsSummary.client.id, this.sessionStorageService.getCompany()!!.id).subscribe((dataWrapper) => {
            console.log(dataWrapper);
            generatedReceiptsSummary.receipts = dataWrapper.items// Simulate fetching data
            generatedReceiptsSummary.totalReceipts = dataWrapper.count; // Set total records for pagination
            this.isLoading = false;
        });
    }



    exportToExcel(generatedReceiptsSummary: GeneratedReceiptsSummary) {
        // Your export logic here
        console.log("Receipts to export:", generatedReceiptsSummary.selectedReceipts);
    }


}
