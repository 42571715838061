<p-toast />
<p-progressBar mode="indeterminate" class="m-2" *ngIf="isLoading"></p-progressBar>
<div class="card pt-2" *ngIf="!isLoading">
    <p-breadcrumb class="max-w-full" [model]="breadcrumbItems" [home]="homeBreadcrumb"></p-breadcrumb>
</div>

<div *ngIf="!isLoading" class="col-12 flex overflow-hidden">
    <div class="flex-none flex align-items-center justify-content-center">
        <div class="pl-4">
            <p class="text-4xl font-bold text-blue-800 p-0 mb-1">Fleet Telematics</p>
            <p-tag icon="pi pi-truck" value="{{vehicleList.length}} Vehicles"></p-tag>
        </div>
    </div>
    <div class="flex-grow-1 flex align-items-center justify-content-center"></div>
</div>

<div class="border-gray-600 bg-gray-50 border-round-lg p-4 ml-1 mt-3 mr-1">

    <div class="grid pt-2">

        <div class="col-12 sm:col-6 md:col-6 lg:col-3 xl:col-3">
            <div class="bg-blue-100 border-round-lg pl-2 pt-2 pb-2 ml-1 mt-1">
                <div class="flex justify-content-between align-items-center pr-4 pl-4">
                    <span style="font-size: 1rem;">On the Move/Total Vehicles</span>
                    <div class="bg-green-200 border-round-lg p-2 m-1">
                        <div class="flex justify-content-between align-items-center">
                            <i class="pi pi-shopping-cart" style="font-size: 1.2rem"></i>
                        </div>
                    </div>
                </div>
                <div class="col-12 flex">
                    <div class="flex-grow-1 grid ml-1 pl-2">
                        <p-progressSpinner [style]="{width: '60px', height: '60px'}" *ngIf="isLoading"></p-progressSpinner>
                        <span  *ngIf="!isLoading" style="font-size: 2.7rem;">{{ getVehiclesCountInMotion() }} / {{ getVehiclesCountInStatic() }}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 sm:col-6 md:col-6 lg:col-3 xl:col-3">
            <div class="bg-blue-100 border-round-lg pl-2 pt-2 pb-2 ml-1 mt-1">
                <div class="flex justify-content-between align-items-center pr-4 pl-4">
                    <span style="font-size: 1rem;">Max Speed</span>
                    <div class="bg-green-200 border-round-lg p-2 m-1">
                        <div class="flex justify-content-between align-items-center">
                            <i class="fa-solid fa-sack-dollar" style="font-size: 1.2rem"></i>
                        </div>
                    </div>
                </div>
                <div class="col-12 flex">
                    <div class="flex-grow-1 grid ml-1 pl-2">
                        <p-progressSpinner [style]="{width: '60px', height: '60px'}" *ngIf="isLoading"></p-progressSpinner>
                        <span  *ngIf="!isLoading" style="font-size: 2.7rem;">{{ getMaxVehicleSpeed() }}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 sm:col-6 md:col-6 lg:col-3 xl:col-3">
            <div class="bg-blue-100 border-round-lg pl-2 pt-2 pb-2 ml-1 mt-1">
                <div class="flex justify-content-between align-items-center pr-4 pl-4">
                    <span style="font-size: 1rem;">Distance Traveled Today</span>
                    <div class="bg-green-200 border-round-lg p-2 m-1">
                        <div class="flex justify-content-between align-items-center">
                            <i class="fa-solid fa-hourglass-half" style="font-size: 1.2rem"></i>
                        </div>
                    </div>
                </div>
                <div class="col-12 flex">
                    <div class="flex-grow-1 grid ml-1 pl-2">
                        <p-progressSpinner [style]="{width: '60px', height: '60px'}" *ngIf="isLoading"></p-progressSpinner>
                        <span  *ngIf="!isLoading" style="font-size: 2.7rem;">{{ getTotalDistanceCovered() }}</span>
                    </div>
                </div>
            </div>
        </div>



        <div class="col-12 sm:col-6 md:col-6 lg:col-3 xl:col-3">
            <div class="bg-blue-100 border-round-lg pl-2 pt-2 pb-2 ml-1 mt-1">
                <div class="flex justify-content-between align-items-center pr-4 pl-4">
                    <span style="font-size: 1rem;">Fuel Consumption Today</span>
                    <div class="bg-green-200 border-round-lg p-2 m-1">
                        <div class="flex justify-content-between align-items-center">
                            <i class="fa-solid fa-stamp" style="font-size: 1.2rem"></i>
                        </div>
                    </div>
                </div>
                <div class="col-12 flex">
                    <div class="flex-grow-1 grid ml-1 pl-2">
                        <p-progressSpinner [style]="{width: '60px', height: '60px'}" *ngIf="isLoading"></p-progressSpinner>
                        <span  *ngIf="!isLoading" style="font-size: 2.7rem;">{{ getTotalFuelConsumed() }}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12">
            <div class="bg-blue-100 border-round-lg pl-2 pt-2 pb-2 ml-1 mt-1">
                <div class="flex justify-content-between align-items-center pr-4 pl-4">
                    <span style="font-size: 1rem;">Vehicle Tracking</span>
                    <div class="bg-green-200 border-round-lg p-2 m-1">
                        <div class="flex justify-content-between align-items-center">
                            <i class="fa-solid fa-map-location-dot" style="font-size: 1.2rem"></i>
                        </div>
                    </div>
                </div>
                <div class="col-12 flex mb-4" style="width: 100vh; height: 480px;">
                    <google-map #map id="map-canvas" height="480px" width="100vh" [zoom]="zoom" [center]="center"
                                [options]="options">
                        <map-marker *ngFor="let vehicle of vehicleList"
                                    [position]="{ lat: vehicle.currentLat ?? center.lat, lng: vehicle.currentLng ?? center.lng }"
                                    [title]="vehicle.plateNumber ?? ''" [icon]="vehicleMarkerImage"
                                    [options]="options" (mapClick)="openInfo(vehicle)">
                            <map-info-window>{{ vehicle.currentLat }}, {{ vehicle.currentLng }}</map-info-window>
                        </map-marker>
                    </google-map>
                </div>
            </div>
        </div>

    </div>



</div>



