import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule, RouterOutlet } from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DataViewModule } from 'primeng/dataview';
import { CardModule } from 'primeng/card';
import { ButtonModule } from "primeng/button";
import { ToastModule } from "primeng/toast";
import { ProgressBarModule } from 'primeng/progressbar';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TableModule } from 'primeng/table';
import { InputTextModule } from "primeng/inputtext";
import { DialogModule } from "primeng/dialog";
import { ChipModule } from 'primeng/chip';
import { ImageModule } from 'primeng/image';

import { fleetRoutes } from "./fleet.routes";
import { FleetComponent } from "./fleet.component";
import { DriverComponent } from "./driver/driver.component";
import { VehicleComponent } from "./vehicle/vehicle.component";
import {ConfirmationService, MessageService} from "primeng/api";
import { EditDriverComponent } from './driver/edit-driver/edit-driver.component';
import { EditVehicleComponent } from './vehicle/edit-vehicle/edit-vehicle.component';
import { DriverService } from "./driver/driver.service";
import {DropdownModule} from "primeng/dropdown";
import {CalendarModule} from "primeng/calendar";
import {VehicleService} from "./vehicle/vehicle.service";
import {FileUploadModule} from "primeng/fileupload";
import {MultiSelectModule} from "primeng/multiselect";
import {TabViewModule} from "primeng/tabview";
import {AutoCompleteModule} from "primeng/autocomplete";
import {AvatarModule} from "primeng/avatar";
import {BadgeModule} from "primeng/badge";
import {FieldsetModule} from "primeng/fieldset";
import {SplitButtonModule} from "primeng/splitbutton";
import {TagModule} from "primeng/tag";
import {ChartModule} from "primeng/chart";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {GoogleMapsModule, MapDirectionsRenderer} from "@angular/google-maps";
import {TraccarFleetService} from "./traccar/traccar-fleet.service";
import {GoogleMapsService} from "../services/google/google-maps-loader.service";
import {GoogleMapsLibrariesLoaderService} from "../services/google/google-libraries-loader.service";
import { TelematicsComponent } from './vehicle/telematics/telematics.component';
import {AccordionModule} from "primeng/accordion";
import {GalleriaModule} from "primeng/galleria";
import {TimelineModule} from "primeng/timeline";




@NgModule({
    imports: [
        RouterModule.forChild(fleetRoutes),
        RouterOutlet,
        FontAwesomeModule,
        FormsModule,
        ButtonModule,
        DataViewModule,
        CardModule,
        CommonModule,
        ToastModule,
        ButtonModule,
        ProgressBarModule,
        BreadcrumbModule,
        TableModule,
        InputTextModule,
        DialogModule,
        ChipModule,
        ImageModule,
        ReactiveFormsModule,
        DropdownModule,
        CalendarModule,
        FileUploadModule,
        MultiSelectModule,
        TabViewModule,
        AutoCompleteModule,
        AvatarModule,
        BadgeModule,
        FieldsetModule,
        SplitButtonModule,
        TagModule,
        ChartModule,
        ProgressSpinnerModule,
        GoogleMapsModule,
        AccordionModule,
        GalleriaModule,
        TimelineModule
    ],
    providers: [
        MessageService,
        DriverService,
        VehicleService,
        TraccarFleetService,
        ConfirmationService,
        GoogleMapsService,
        MapDirectionsRenderer,
        GoogleMapsLibrariesLoaderService,
    ],
    declarations: [
        FleetComponent,
        DriverComponent,
        VehicleComponent,
        EditDriverComponent,
        EditVehicleComponent,
        TelematicsComponent,
    ],
})
export class FleetModule { }
