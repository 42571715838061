import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {environment} from "../../../environments/environment";
import {map, Observable} from "rxjs";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {TraccarDevice} from "./traccar-device.model";
import {TraccarPosition} from "./traccar-position.model";

@Injectable({
    providedIn: 'root'
})
export class TraccarFleetService {

    private baseUrl = `${environment.traccarFleetApiUrl}`;

    constructor(private http: HttpClient, private firestore: AngularFirestore) { }

    getGpsDevicesByName(name: string): Observable<TraccarDevice[]> {
        return this.firestore.collection<TraccarDevice>('gpsDevice',
                ref => ref.where('name', '==', name))
            .snapshotChanges().pipe(
                map(actions =>
                    actions.map(a => {
                        const data = a.payload.doc.data() as TraccarDevice;
                        const id = a.payload.doc.id;
                        return data;
                    })
                )
            );
    }

    getGpsPositionByDeviceId(deviceId: number): Observable<TraccarPosition> {
        return this.firestore.collection<TraccarPosition>('gpsPosition').doc(deviceId.toString())
            .snapshotChanges().pipe(map(a => {
                        const data = a.payload.data() as TraccarPosition;
                        const id = a.payload.id;
                        return data;
                    }
                )
            );
    }

    findPositionsByDeviceId(deviceId: number, from: Date, to: Date): Observable<TraccarPosition[]> { // Adjust return type if needed
        const params = { deviceId: deviceId.toString(), from: from.toISOString(), to: to.toISOString() };
        return this.http.get<TraccarPosition[]>(`${this.baseUrl}/positions`, { params });
    }
}
