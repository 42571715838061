import { Routes } from '@angular/router';
import { AccountsComponent } from './accounts.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { ReceiptComponent } from './receipt/receipt.component';
import {ReceivablesComponent} from "./receivables/receivables.component";
import {PayablesComponent} from "./payables/payables.component";
import {BillComponent} from "./bill/bill.component";
import {VoucherComponent} from "./voucher/voucher.component";
import {canActivate} from "@angular/fire/auth-guard";
import {AccountsGuard} from "./accounts.guard";
import {GeneratedInvoicesComponent} from "./receivables/generated-invoices/generated-invoices.component";
import {GeneratedReceiptsComponent} from "./receivables/generated-receipts/generated-receipts.component";

export const accountsRoutes: Routes = [
    {
        path: 'accounts',
        children: [
            {
                path: '',
                component: AccountsComponent,
            },
            {
                path: 'invoice',
                component: InvoiceComponent,
            },
            {
                path: 'receipt',
                component: ReceiptComponent
            },
            {
                path: 'enterprise',
                canActivate: [AccountsGuard],
                children: [
                    {
                        path: 'bill',
                        component: BillComponent,
                    },
                    {
                        path: 'voucher',
                        component: VoucherComponent
                    },
                    {
                        path: 'receivables',
                        children: [
                            {
                                path: '',
                                component: ReceivablesComponent,
                            },
                            {
                                path: 'generated-invoices',
                                component: GeneratedInvoicesComponent,
                            },
                            {
                                path: 'generated-receipts',
                                component: GeneratedReceiptsComponent,
                            }
                        ]
                    },
                    {
                        path: 'payables',
                        component: PayablesComponent
                    },
                ]
            },
        ]
    },
];
